import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = ({ location }) => (
  <Layout>
    <SEO title="Page Not Found" location={location} />
    <div className="page relative">
      <div className="absolute pin-t pin-l mt-6 ml-6 lg:mt-14 lg:ml-14">
        <h1 className="text-sm md:text-2xl xl:text-4xl font-medium">
          Page not found
        </h1>
        <p className="text-sm md:text-2xl xl:text-4xl mb-10">
          Sorry, you just hit a page that doesn&#39;t exist&hellip;
        </p>
        <Link
          to="/"
          className="text-inherit no-underline hover:underline focus:underline"
        >
          Go back home
        </Link>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
